<template>
  <div class="bg-white shadow overflow-hidden mt-5">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Szczegóły płatności
      </h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500"></p>
    </div>
    <div v-if="props.orderPayment === null">
      <div class="rounded-md bg-yellow-50 p-4">
        <div class="flex">
          <div class="ml-3">
            <h3 class="text-sm font-medium text-yellow-800">
              Zamówienie nie zostało jeszcze opłacone!
            </h3>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="border-t border-gray-200">
      <dl>
        <div
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Identyfikator</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ props.orderPayment.pi_id }}
          </dd>
        </div>

        <div
          class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Status</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <PaymentStatusBadge
              :label="props.orderPayment.status"
              :color="mapPaymentStatusToColor(props.orderPayment.status)"
            />
          </dd>
        </div>
        <div
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Do zapłaty</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ props.orderPayment.amount }} {{ props.orderPayment.currency }}
          </dd>
        </div>
        <div
          class="bg-white-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Zapłacono</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ props.orderPayment.amount_received }}
            {{ props.orderPayment.currency }}
          </dd>
        </div>
        <div
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Metoda płatności</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ props.orderPayment.payment_method_types }}
          </dd>
        </div>
        <div
          class="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">Link do panelu</dt>
          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <div class="btn-main">
              <a :href="stripeDetails" target="_blank">
                Szczegóły zamówienia (Stripe)
              </a>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>
<script setup>
import {defineProps, ref} from 'vue';
import PaymentStatusBadge from '@/components/badges/PaymentStatusBadge.vue';
import { PaymentStatusEnum } from '@/enums/PaymentStatusEnum';

const props = defineProps({
  orderPayment: {
    type: Object,
  },
});

const stripeUrl = ref(process.env.VUE_APP_STRIPE_URL);
const stripeDetails =
  stripeUrl.value + 'payments' + props.orderPayment.stripe_details;

function mapPaymentStatusToColor(status) {
  switch (status) {
    case PaymentStatusEnum.REQUIRES_PAYMENT_METHOD:
      return 'blue';
    case PaymentStatusEnum.REQUIRES_CONFIRMATION:
      return 'blue';
    case PaymentStatusEnum.REQUIRES_ACTION:
      return 'blue';
    case PaymentStatusEnum.REQUIRES_CAPTURE:
      return 'blue';
    case PaymentStatusEnum.PROCESSING:
      return 'purple';
    case PaymentStatusEnum.CANCELED:
      return 'yellow';
    case PaymentStatusEnum.SUCCEEDED:
      return 'green';
    default:
      return 'gray';
  }
}
</script>
